import { useState, useEffect, useRef } from 'react';
import './App.css'; // Import your CSS file for the modal styles

// Define an interface for the expected API response
interface Talent {
  talent_index: string;
  talent_uppercase: string;
  job: string;
  country: string;
  profile_picture: string;
}

const TalentSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<Talent[]>([]);
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const [indexLanguage, setIndexLanguage] = useState('');

  useEffect(() => {
    // Get the language from localStorage when the component mounts
    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
    setIndexLanguage(storedLanguage);
  }, []); // Empty dependency array ensures this runs once on mount

  // Fetch results when search term changes
  useEffect(() => {
    if (searchTerm.length > 2) {
      // Define the API endpoint
      const apiEndpoint = `https://peaxel.me/wp-json/custom/v1/custom-search?searchterm=${searchTerm}`;

      // Make the fetch request
      fetch(apiEndpoint)
        .then(response => response.json())
        .then(data => setSearchResults(data))
        .catch(error => console.error('Error fetching search results:', error));
      
    } else {
      const apiEndpoint = `https://peaxel.me/wp-json/custom/v1/custom-search?searchterm=discover`;

      // Make the fetch request
      fetch(apiEndpoint)
        .then(response => response.json())
        .then(data => setSearchResults(data))
        .catch(error => console.error('Error fetching search results:', error));
      
    }
  }, [searchTerm]);

  const handleClickOutside = (event: MouseEvent) => {
    // Close modal if clicking outside
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setShowModal(false);
    }
  };

  // Add click event listener to close modal when clicked outside
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  return (
    <div>
      <span onClick={() => setShowModal(true)} className="button-search"><i className="fa-solid fa-magnifying-glass"></i></span>

      {showModal && (
        <div className='aroundModal'>
          <div className="modal-container" ref={modalRef}>
            
            <button 
              className="close-button-modal" 
              onClick={() => setShowModal(false)}>
             <i className="fa-regular fa-circle-xmark"></i>
            </button>



            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search for Talents..."
            />
            {searchResults.slice(0, 8).map(result => (
              <a
                key={result.talent_index}
                className='line-search'
                href={`https://peaxel.me/${indexLanguage}/profile/?${result.talent_uppercase}/`}
                rel="noopener noreferrer" // Recommended for security reasons
              >
                <span>
                  <img src={result.profile_picture} alt={result.talent_index} className='asset-img-search' />
                </span>
                <span className='details-search'>
                  <span>
                    <strong>{result.talent_uppercase}</strong>
                  </span>
                  <span className='job-search'>
                    <div>{result.job}&nbsp;•&nbsp;{result.country}</div>
                  </span>
                </span>
              </a>
            ))}
            <a href={`https://peaxel.me/${indexLanguage}/talents-discovery`}>
              <span className='advanced-search-link'>Advanced Search</span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default TalentSearch;
